body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.filled-img {
  width: 12em;
  height: 15em;
  object-fit: cover;
}

.product-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.product-list {
  display: flex;
  width: 500px;

  flex-wrap: wrap; /* Allows the items to wrap to the next line if necessary */
  gap: 20px; /* Adds space between each product item */
  padding: 0;
  list-style-type: none; /* Removes default list styling */
}

.product-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 20px;
}

.product-item h3 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
}

.product-item img {
  /* width: 200px; */
  height: 200px; /* Maintain aspect ratio */
  margin: 10px; /* Add margin for spacing */
}


.edit-product-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.edit-product-container h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
}

.image-preview {
  width: 200px;
  height: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
